import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Card',{attrs:{"title":"Profile","icon":"mdi-card-account-details"}},[_c('EditableObject',{attrs:{"object":_vm.$store.state.user,"fields":[{fieldName:'name', displayName:'Name', type:String, editable: true, rules:[_vm.rules.required]},
      {fieldName:'username', displayName:'Username', type:String, editable: true, rules:[_vm.rules.required, _vm.rules.username]}],"saveMethod":_vm.saveUser}}),_c('EditableObject',{attrs:{"showHeaderDivider":true,"object":_vm.$store.state.user,"fields":[
                  {fieldName:'verifiedEmailAddress', displayName:'Email', clickable: true},
                  {fieldName:'email', displayName:'Email', type:String, hidden: true, editable: true,
                    rules:[_vm.rules.required, _vm.rules.email]},
               ],"cancelCallback":_vm.clearSentVerificationEmail},scopedSlots:_vm._u([{key:"default",fn:function({reloadForm, object, fields,  cancelCallback, focusIndex, successCallback}){return [_c('div',{key:reloadForm},[(!_vm.sentVerificationEmail)?_c('DynamicForm',{attrs:{"object":object,"fields":fields,"saveMethod":_vm.sendVerificationEmail,"cancelCallback":cancelCallback,"focusIndex":focusIndex,"saveText":"Verify email","successCallback":_vm.emailVerificationSent}}):_c('DynamicForm',{attrs:{"object":{id: _vm.$store.state.user.id, version: _vm.$store.state.user.version, email:_vm.sentVerificationEmail},"fields":[
                  {fieldName:'email', displayName:'Email', type:String, readOnly: true},
                  {fieldName:'token', displayName:'Security Code', type:String, rules:[_vm.rules.required, _vm.rules.token]}
               ],"cancelCallback":cancelCallback,"focusIndex":1,"saveMethod":_vm.saveEmail,"successCallback":successCallback}})],1)]}}])})],1),_c('Card',{attrs:{"title":"Password","icon":"$userPassword"}},[_c('EditableObject',{attrs:{"object":_vm.$store.state.user,"fields":[
        {fieldName:'passwordLastChanged', displayName:'Password last changed', clickable: true, type:'moment.fromNow'},
        {fieldName:'currentPassword', displayName:'Current Password', hidden:true, editable: true, type:String, rules:[_vm.rules.required], password: true},
        {fieldName:'newPassword', displayName:'New Password', hidden:true, editable: true, type:String, rules:[_vm.rules.required, _vm.rules.password], password:true},
        ],"saveMethod":_vm.saveNewPassword}})],1),_c('Card',{attrs:{"title":"Passkeys","icon":"$userPasskey"}},[_c('UserPasskeys')],1),_c('Card',{attrs:{"title":"Notification Subscriptions","icon":"mdi-bell"}},[_c('NotificationSubscriptions')],1),_c('Card',{attrs:{"title":"Sign in history","icon":"mdi-history"}},[(_vm.successfulLogins)?_c(VExpansionPanels,{attrs:{"focusable":"","flat":"","tile":"","inset":""}},_vm._l((_vm.successfulLogins),function(object,index){return _c(VExpansionPanel,{key:index},[(index != 0)?_c(VDivider):_vm._e(),_c(VExpansionPanelHeader,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_vm._v(" "+_vm._s(_vm.moment(object.timestamp).fromNow())+" ")]),_c(VCol,[_vm._v(" "+_vm._s(object.remoteIP)+" ")])],1)],1),_c(VExpansionPanelContent,[_vm._v(" "+_vm._s(_vm.moment(object.timestamp))+" ")])],1)}),1):_vm._e()],1),_c('Card',{attrs:{"title":"Failed sign in attempts","icon":"mdi-cctv"}},[(_vm.failedLogins)?_c(VExpansionPanels,{attrs:{"focusable":"","flat":"","tile":"","inset":""}},_vm._l((_vm.failedLogins),function(object,index){return _c(VExpansionPanel,{key:object.timestamp},[(index != 0)?_c(VDivider):_vm._e(),_c(VExpansionPanelHeader,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_vm._v(" "+_vm._s(_vm.moment(object.timestamp).fromNow())+" ")]),_c(VCol,[_vm._v(" "+_vm._s(object.remoteIP)+" ")])],1)],1),_c(VExpansionPanelContent,[_vm._v(" "+_vm._s(_vm.moment(object.timestamp))+" ")])],1)}),1):_vm._e()],1),_c('Card',{attrs:{"title":"Create Household","icon":"mdi-home-group"}},[_c('DynamicForm',{key:_vm.reloadForm,attrs:{"object":{},"fields":[{fieldName:'name', displayName:'Name', type: String}],"save-method":(data) => this.$api.household.createHousehold(data.name),"cancel-callback":() => this.reloadForm = !this.reloadForm,"success-callback":(data) => _vm.$router.push('/h/' + data.id),"saveText":"Create"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }