import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VForm,{on:{"submit":_vm.attemptSignin}},[_c(VCardText,[_c('div',{staticStyle:{"text-align":"center"}},[_c(VBtn,{attrs:{"color":"primary","loading":_vm.isLoggingInPasskey,"disabled":_vm.isLoggingInPasskey},on:{"click":_vm.startAssertion}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("$userPasskey")]),_vm._v("Passkey Sign in")],1)],1),(_vm.errorMessagePasskey)?_c('div',{staticStyle:{"color":"red","text-align":"center"}},[_c('br'),_vm._v("Error: "+_vm._s(_vm.errorMessagePasskey))]):_vm._e(),_c('br'),_c(VDivider),_c(VTextField,{attrs:{"autofocus":"","label":"Username","prepend-icon":"mdi-account","readonly":_vm.isLoggingIn,"error-messages":_vm.errorMessage ? ' ' : ''},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTextField,{attrs:{"type":"password","prepend-icon":"mdi-lock","label":"Password","readonly":_vm.isLoggingIn,"error-messages":_vm.errorMessage},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"color":"secondary","to":"register"}},[_vm._v("Register")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","type":"submit","disabled":!_vm.isLoginFormValid,"loading":_vm.isLoggingIn},on:{"click":_vm.attemptSignin}},[_vm._v("Sign in")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }