import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VBtn,{attrs:{"color":_vm.buttonColor,"small":_vm.small,"icon":!_vm.buttonText},on:{"click":function($event){_vm.showDialog = true}}},[(_vm.buttonIcon)?_c(VIcon,{attrs:{"left":_vm.buttonText}},[_vm._v(_vm._s(_vm.buttonIcon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.buttonText)+" ")],1),_c(VDialog,{attrs:{"max-width":"600","persistent":_vm.isDoingAction},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('Card',{attrs:{"icon":_vm.confirmationIcon,"title":_vm.confirmationText,"inDialog":""}},[_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.isDoingAction},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":_vm.confirmationColor ? _vm.confirmationColor : _vm.buttonColor,"loading":_vm.isDoingAction},on:{"click":_vm.doAction}},[(_vm.buttonIcon)?_c(VIcon,[_vm._v(_vm._s(_vm.buttonIcon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.confirmationButtonText ? _vm.confirmationButtonText : _vm.buttonText)+" ")],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }