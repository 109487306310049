<template>
  <v-form @submit="attemptSignin">
    <v-card-text>
      <div style="text-align: center;">
        <v-btn color="primary" @click="startAssertion" :loading="isLoggingInPasskey" :disabled="isLoggingInPasskey"><v-icon left>$userPasskey</v-icon>Passkey Sign in</v-btn>
      </div>
      <div v-if="errorMessagePasskey" style="color: red; text-align: center;"><br/>Error: {{errorMessagePasskey}}</div>
      <br/>
      <v-divider></v-divider>
      <v-text-field autofocus v-model="username" label="Username" prepend-icon="mdi-account" :readonly="isLoggingIn" :error-messages="errorMessage ? ' ' : ''"/>
      <v-text-field v-model="password" type="password" prepend-icon="mdi-lock" label="Password" :readonly="isLoggingIn" :error-messages="errorMessage"/>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" to="register">Register</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" type="submit" @click="attemptSignin" :disabled="!isLoginFormValid" :loading="isLoggingIn">Sign in</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { api } from '@/main'
import * as webauthnJson from '@github/webauthn-json'

export default {
  name: 'Login',

  data: () => ({
    isLoggingIn: false,
    username: "",
    password: "",
    errorMessage: "",
    isLoggingInPasskey: false,
    errorMessagePasskey: "",
  }),

  computed: {
    isLoginFormValid() {
      return this.username && this.username.length > 0 && this.password && this.password.length > 0 && !this.errorMessage;
    }
  },

  methods: {
    attemptSignin: function (e) {
      e.preventDefault();

      if (!this.isLoginFormValid)
        return;

      this.isLoggingIn = true;
      this.errorMessage = "";
      // this.$store.commit('logIn');

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vue = this;
      this.$api.signIn(this.username, this.password)
        .then(() => this.$api.connectWebsocket())
        .catch(() => {
          vue.errorMessage = "Invalid username or password";
          vue.isLoggingIn = false;
        });
    },
    startAssertion: function() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vue = this;
      api.passkey.startAssertion().then(passkeyRequest => {
        const json = passkeyRequest.webauthnRequest;
        const credentialGetOptions = JSON.parse(json);
        webauthnJson.get(credentialGetOptions).then(publicKeyCredential => {
          this.$api.signIn(passkeyRequest.id, JSON.stringify(publicKeyCredential), "passkey")
            .then(() => this.$api.connectWebsocket())
            .catch(() => {
              vue.errorMessagePasskey = "Invalid Passkey";
              vue.isLoggingInPasskey = false;
            });
        }, error => {
          console.dir("error: " + JSON.stringify(error));
          console.dir(error);
        })
      })
    }
  },

  watch: {
    username: function() {
      this.errorMessage = "";
    },
    password: function() {
      this.errorMessage = "";
    }
  }
}
</script>

<style scoped>

</style>
