<template>
  <div>
    <Card title="Profile" icon="mdi-card-account-details">
      <EditableObject
        :object="$store.state.user"
        :fields="[{fieldName:'name', displayName:'Name', type:String, editable: true, rules:[rules.required]},
        {fieldName:'username', displayName:'Username', type:String, editable: true, rules:[rules.required, rules.username]}]"
        :saveMethod="saveUser"
      ></EditableObject>

      <EditableObject
        :showHeaderDivider="true"
        :object="$store.state.user"
        :fields="[
                    {fieldName:'verifiedEmailAddress', displayName:'Email', clickable: true},
                    {fieldName:'email', displayName:'Email', type:String, hidden: true, editable: true,
                      rules:[rules.required, rules.email]},
                 ]"
        :cancelCallback="clearSentVerificationEmail"
      >
        <template v-slot="{reloadForm, object, fields,  cancelCallback, focusIndex, successCallback}">
          <div :key="reloadForm">
            <DynamicForm
              v-if="!sentVerificationEmail"

              :object="object"
              :fields="fields"
              :saveMethod="sendVerificationEmail"
              :cancelCallback="cancelCallback"
              :focusIndex="focusIndex"
              saveText="Verify email"
              :successCallback="emailVerificationSent"
            > </DynamicForm>
            <DynamicForm
              v-else
              :object="{id: $store.state.user.id, version: $store.state.user.version, email:sentVerificationEmail}"
              :fields="[
                    {fieldName:'email', displayName:'Email', type:String, readOnly: true},
                    {fieldName:'token', displayName:'Security Code', type:String, rules:[rules.required, rules.token]}
                 ]"
              :cancelCallback="cancelCallback"
              :focusIndex="1"
              :saveMethod="saveEmail"
              :successCallback="successCallback"
            ></DynamicForm>
          </div>
        </template>
      </EditableObject>
    </Card>

    <Card title="Password" icon="$userPassword">
      <EditableObject
        :object="$store.state.user"
        :fields="[
          {fieldName:'passwordLastChanged', displayName:'Password last changed', clickable: true, type:'moment.fromNow'},
          {fieldName:'currentPassword', displayName:'Current Password', hidden:true, editable: true, type:String, rules:[rules.required], password: true},
          {fieldName:'newPassword', displayName:'New Password', hidden:true, editable: true, type:String, rules:[rules.required, rules.password], password:true},
          ]"
        :saveMethod="saveNewPassword"
      ></EditableObject>
    </Card>

    <Card title="Passkeys" icon="$userPasskey">
      <UserPasskeys></UserPasskeys>
    </Card>

    <Card title="Notification Subscriptions" icon="mdi-bell">
      <NotificationSubscriptions />
    </Card>

    <Card title="Sign in history" icon="mdi-history">
      <v-expansion-panels v-if="successfulLogins" focusable flat tile inset>
        <v-expansion-panel v-for="(object, index) in successfulLogins" v-bind:key="index">
          <v-divider v-if="index != 0"/>
          <v-expansion-panel-header>
            <v-row no-gutters>
              <v-col>
                {{moment(object.timestamp).fromNow()}}
              </v-col>
              <v-col>
                {{object.remoteIP}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{moment(object.timestamp)}}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </Card>

    <Card title="Failed sign in attempts" icon="mdi-cctv">
      <v-expansion-panels v-if="failedLogins" focusable flat tile inset>
        <v-expansion-panel v-for="(object, index) in failedLogins" v-bind:key="object.timestamp">
          <v-divider v-if="index != 0"/>
          <v-expansion-panel-header>
            <v-row no-gutters>
              <v-col>
                {{moment(object.timestamp).fromNow()}}
              </v-col>
              <v-col>
                {{object.remoteIP}}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{moment(object.timestamp)}}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </Card>

    <Card title="Create Household" icon="mdi-home-group">
      <DynamicForm
        :key="reloadForm"
        :object="{}"
        :fields="[{fieldName:'name', displayName:'Name', type: String}]"
        :save-method="(data) => this.$api.household.createHousehold(data.name)"
        :cancel-callback="() => this.reloadForm = !this.reloadForm"
        :success-callback="(data) => $router.push('/h/' + data.id)"
        saveText="Create"
      ></DynamicForm>
    </Card>
  </div>

</template>

<script>
  import Card from '@/components/Card'
  import EditableObject from '@/components/EditableObject'
  import DynamicForm from '@/components/DynamicForm'
  import NotificationSubscriptions from '@/hoamodules/user/NotificationSubscriptions.vue'
  import UserPasskeys from '@/components/UserPasskeys.vue'

  export default {
    name: 'Settings',

    components: {
      UserPasskeys,
      NotificationSubscriptions,
      DynamicForm,
      EditableObject,
      Card
    },

    data: () => ({
      sentVerificationEmail: null,
      successfulLogins: null,
      failedLogins: null,
      reloadForm: false,
      rules: {
        required: value => !!value || 'Required',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid email'
        },
        token: value => {
          const pattern = /^\d{6}$/
          return pattern.test(value) || 'Security code is 6 numbers'
        },
        password: value => {
          const pattern = /(?=.{8,})(?=.*[a-zA-Z])(?=.*[!#$%&?_\- "]).*$/
          if (value && value.length > 60)
            return "Password must be less than 60 characters";
          return pattern.test(value) || 'Password must be between 8 and 60 characters and include upper case letters, lower case letters, and one of the following: !#$%&? _-'
        },
        username: value => {
          const pattern = /^\w{3,100}$/
          return pattern.test(value) || 'Username must be between 3 and 100 characters and only include letters, numbers, and _'
        }
      }
    }),

    methods: {
      saveUser: function(user) {
        return this.$api.user.saveUserProfile(user);
      },
      sendVerificationEmail: function(object) {
        return this.$api.user.sendVerificationEmail(object.email);
      },
      emailVerificationSent: function(email) {
        this.sentVerificationEmail = email;
      },
      clearSentVerificationEmail: function() {
        this.sentVerificationEmail = null;
      },
      saveEmail: function(user) {
        return this.$api.user.saveUserEmail(user.id, user.version, user.email, user.token);
      },
      getSuccessfulLogins: function() {
        this.$api.user.getSuccessfulLogins().then(successfulLogins => {
          this.successfulLogins = successfulLogins
        });
      },
      getFailedLogins: function() {
        this.$api.user.getFailedLogins().then(failedLogins => {
          this.failedLogins = failedLogins
        });
      },
      saveNewPassword: function(user) {
        return this.$api.user.changePassword(user.id, user.version, user.currentPassword, user.newPassword);
      },
    },
    mounted() {
      this.$emit('breadcrumbs', [{
        text: 'User Settings',
        icon: 'mdi-cog',
        to: '/account'
      }]);
      this.$emit('menuItems', []);

      this.getSuccessfulLogins();
      this.getFailedLogins();
    },
  }
</script>

<style scoped>

</style>
