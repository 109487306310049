<template>
  <div>
    <Card
      v-if="uncategorizedEnvelope && (uncategorizedEnvelope.transactionCount > 0 || (uncategorizedTransactions))"
      icon="mdi-cash-multiple" :title="uncategorizedEnvelope.transactionCount + ' Uncategorized Transaction' + (uncategorizedEnvelope.transactionCount === 1 ? '' : 's') + ' ' + formatMoney(uncategorizedEnvelope.amount)"
      :actions="[{icon: 'mdi-refresh', click: this.searchForUncategorizedTransactions}]"
      wide
      :style="uncategorizedTransactions && uncategorizedTransactions.length < 1 ? 'display: none;' : ''"
    >
      <TransactionTable
        v-if="uncategorizedTransactions"
        :transactions="uncategorizedTransactions"
        :financialModule="financialModule"
        :accounts="accounts"
        :envelopes="envelopes"
        :mySecurities="mySecurities"
        :incomeSources="incomeSources"
        :showQuickAssignEnvelopeButton="true"
        :envelopeIdToAdd="uncategorizedEnvelope.id"
      />
    </Card>

    <Card icon="mdi-email-multiple" :title="'Envelopes ' + formatMoney(envelopes.reduce((a, b) => a + (b.totalAmount || 0), 0))" wide>
      <draggable :list="envelopes" @end="savePositions" :disabled="isSaving || !showHiddenEnvelopes">
        <span v-for="envelope in envelopes" :key="envelope.id">
          <div v-if="(showHiddenEnvelopes || !(envelope.hideWhenZero && envelope.amount === 0 && envelope.earmarks.length == 0)) && !envelope.deletedTimestamp"
               :style="'padding: 0px 4px 8px 4px; display:inline-block; vertical-align : top;' + ($vuetify.breakpoint.xs ? ' min-width: 100%;' : '')">

            <Card
              in-dialog
              :title="envelope.name"
              :title-right="formatMoney(envelope.amount)"
              :title-right-color="envelope.amount < 0 ? '#FF0000' : undefined "
              :errorTheme="envelope.totalAmount < 0"
              :to="'/h/' + household.id + '/f/' + financialModule.id + '/e/' + envelope.id"
            >
              <div v-if="envelope.earmarks && envelope.earmarks.length > 0" style="padding: 0px 8px 16px;">
                <div v-for="(earmark, index) in envelope.earmarks" v-bind:key="index">
                  <span>{{earmark.name}}</span>
                  <span style="float: right; padding-left: 16px;">{{formatMoney(earmark.amount)}}</span>
                </div>
              </div>
              <v-card-actions style="padding-bottom: 0px;">
                <v-spacer/>
                <v-btn small fab :elevation="0" :to="'/h/' + household.id + '/f/' + financialModule.id + '/transactions/e/' + envelope.id">
                  <v-icon>mdi-email-newsletter</v-icon>
                </v-btn>
                <v-btn v-if="envelope.userEditable" small fab :elevation="0" @click.prevent="envelopeToEdit = envelope; editEnvelopeDialog = true;">
                  <v-icon>mdi-email-edit</v-icon>
                </v-btn>
              </v-card-actions>
            </Card>
          </div>
        </span>
      </draggable>

      <v-card-actions style="padding-bottom: 0px;">
        <v-switch label="Show Hidden Envelopes" v-model="showHiddenEnvelopes" inset></v-switch>
        <v-spacer/>
        <v-btn color="primary" small fab :elevation="0" @click="createTransferDialog = true;">
          <v-icon>mdi-cash-plus</v-icon>
        </v-btn>
        <v-btn color="primary" small fab :elevation="0" @click="envelopeToEdit = {financialModuleId: financialModule.id, earmarks: [], position: envelopes.length}; editEnvelopeDialog = true;">
          <v-icon>mdi-email-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </Card>

    <Card icon="mdi-chart-line" title="Details" wide>
    </Card>

    <v-dialog v-model="editEnvelopeDialog" max-width="1023">
      <Card :title="envelopeToEdit.id ? 'Edit Envelope' : 'Create a New Envelope'" icon="mdi-email" inDialog>
        {{envelopeToEdit.id}}
        <DynamicForm
          :key="editEnvelopeDialog"
          :object="envelopeToEdit"
          :fields="[
            {fieldName:'name', displayName:'Name', type: String, rules:[rules.required]},
            {fieldName:'targetInterval', displayName:'Interval', type:'Enumerator', rules:[rules.required], values:getEnumArray(targetInterval) },
            {fieldName:'targetAmount', displayName:'Amount per Paycheck', type: 'Currency', displayCondition:displayConditions.isPerPaycheck},
            {fieldName:'targetAmount', displayName:'Amount per Month', type: 'Currency', displayCondition:displayConditions.isMonthly},
            {fieldName:'targetAmount', displayName:'Amount per Year', type: 'Currency', displayCondition:displayConditions.isAnnual},
            {fieldName:'targetAmount', displayName:'Target', type: 'Currency', displayCondition:displayConditions.isTopUp},
            {fieldName:'maxAmountPerPaycheck', displayName:'Max Amount per Paycheck', type: 'Currency', hint: 'Enter 0 for no limit', displayCondition:displayConditions.isTopUp},
            {fieldName:'description', displayName:'Description', type: String, rules:[]},
            {fieldName:'earmarks', displayName:'Earmarks', type: 'NamedCurrencyList', rules:[rules.required]},
            {fieldName:'hideWhenZero', displayName:'Hide when zero', type: Boolean},
            ]"
          :save-method="(data) => this.$api.financial_transaction.saveEnvelope(data)"
          :delete-method="envelopeToEdit.id && envelopeMap[envelopeToEdit.id] && envelopeMap[envelopeToEdit.id].transactionCount === 0 ? (data) => this.$api.financial_transaction.deleteEnvelope(data) : null"
          :cancel-callback="() => this.editEnvelopeDialog = false"
          :success-callback="(o) => {this.editEnvelopeDialog = false}"
          :focus-index="0"
        ></DynamicForm>
<!--          :delete-method="envelopeToEdit.id && envelopeMap[envelopeToEdit.id].transactionCount === 0 ? (data) => this.$api.financial_transaction.saveEnvelope(data) : null"-->
      </Card>
    </v-dialog>

    <v-dialog v-model="createTransferDialog" max-width="1023">
      <Card :title="'Transfer'" icon="mdi-cash" inDialog>
        <DynamicForm
          :key="createTransferDialog"
          :object="{description: 'Transfer', date: new Date(new Date() - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().split('T')[0]}"
          :fields="[
            {fieldName:'date', displayName:'Date', type: 'LocalDate', small: true, rules:[rules.required]},
            {fieldName:'memo', displayName:'Memo', type: String},
            {fieldName:'fromEnvelopeId', displayName:'From', type:'Enumerator', rules:[rules.required], values:envelopesForDropdown },
            {fieldName:'toEnvelopeId', displayName:'To', type:'Enumerator', rules:[rules.required], values:envelopesForDropdown },
            {fieldName:'amount', displayName:'Amount', type: 'Currency', rules:[rules.required]},
            ]"
          :save-method="(data) => this.$api.financial_transaction.transferAmount(financialModule.id, data.date, data.memo, data.fromEnvelopeId, data.toEnvelopeId, data.amount)"
          :cancel-callback="() => this.createTransferDialog = false"
          :success-callback="(o) => {this.createTransferDialog = false}"
          :focus-index="0"
        ></DynamicForm>
      </Card>
    </v-dialog>

  </div>
</template>

<script>
import Card from '@/components/Card'
import DynamicForm from '@/components/DynamicForm'
import draggable from 'vuedraggable'
import { TargetInterval } from '@/api'
import TransactionTable from '@/hoamodules/financial/TransactionTable.vue'

// import { Pie as PieChart } from 'vue-chartjs'
// import {
//   Chart as ChartJS,
//   ArcElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js'
// ChartJS.register(Title, Tooltip, Legend, ArcElement)

export default {
  name: 'Envelopes',
  // components: { TransactionTable, DynamicForm, Card, draggable, PieChart },
  components: { TransactionTable, DynamicForm, Card, draggable },
  props: ['household', 'financialModule', 'accounts', 'envelopes', 'mySecurities', 'incomeSources'],

  data: () => ({
    editEnvelopeDialog: false,
    envelopeToEdit: { },
    showHiddenEnvelopes: false,
    isSaving: false,
    rules: {
      required: value => !!value || 'Required',
    },
    targetInterval: TargetInterval,
    displayConditions: {
      isPerPaycheck(object) {
        return object && object.targetInterval === "PAYCHECK";
      },
      isMonthly(object) {
        return object && object.targetInterval === "MONTHLY";
      },
      isAnnual(object) {
        return object && object.targetInterval === "ANNUAL";
      },
      isTopUp(object) {
        return object && object.targetInterval === "TOP_UP";
      },
    },
    createTransferDialog: false,
    uncategorizedTransactions: null,
  }),
  computed: {
    envelopesForDropdown() {
      const parsableArray = [];
      this.envelopes.forEach(envelope => {
        // Exclude Unprocessed
        if (envelope.id !== envelope.financialModuleId) {
          parsableArray.push({
            text: envelope.name + ': ' + this.formatMoney(envelope.amount),
            value: envelope.id
          });
        }
      });
      return parsableArray;
    },
    uncategorizedEnvelope() {
      let uncategorizedEnvelope = null;
      this.envelopes.forEach(envelope => {
        if (envelope.id === envelope.financialModuleId) {
          uncategorizedEnvelope = envelope;
        }
      });
      return uncategorizedEnvelope;
    },
    envelopeMap() {
      const names = {};
      this.envelopes.forEach(envelope => {
        names[envelope.id] = envelope
      });
      return names;
    },
  },
  methods: {
    savePositions() {
      // Save!
      const dirtyItems = [];
      this.envelopes.forEach((item, index) => {
        if (item.position !== index)
          item.position = index;
        dirtyItems.push(item);
      });
      dirtyItems.forEach((item) => {
        this.$api.financial_transaction.saveEnvelope(item);
      })
    },
    searchForUncategorizedTransactions() {
      this.uncategorizedTransactions = null;
      this.$api.financial_transaction.getTransactions(this.financialModule.id, [], [this.financialModule.id],
        null, null, '', 0).then(transactions => {
        transactions.forEach(newTransaction => {
          if (newTransaction.envelopes)
            newTransaction.newEnvelopes = JSON.parse(JSON.stringify(newTransaction.envelopes));
        });
        transactions.sort((a, b) => b.date.localeCompare(a.date));
        this.uncategorizedTransactions = transactions;
      });
    },
  },
  mounted() {
    this.$emit('breadcrumbs', [
      {
        text: 'Envelopes',
        icon: 'mdi-email-multiple',
        to: '/h/' + this.household.id + '/f/' + this.financialModule.id + '/envelopes'
      }
    ]);

    this.searchForUncategorizedTransactions();
  },
}
</script>

<style>

/*.envelope::before,*/
/*.envelope::after {*/
/*  position: absolute;*/
/*  content: '';*/
/*  width: 50%;*/
/*  height: 1px; !* cross thickness *!*/
/*  background-color: white;*/
/*}*/
/*.envelope::before {*/
/*  transform: rotate(30deg);*/
/*  transform-origin: 0 0;*/
/*}*/

/*.envelope::after {*/
/*  !*transform: rotate(-45deg);*!*/
/*}*/
</style>
