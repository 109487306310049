import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.EditableTable,{attrs:{"table-subscription":(handler) => _vm.$api.passkey.subscribeToUserPasskeys(_vm.$store.state.user.id, handler),"fields":[
        {fieldName:'displayName', displayName: 'Name', editable: false, type: String, rules:[]},
        {fieldName:'lastUsedTimestamp', displayName: 'Last Used', editable: false, type: 'Timestamp', rules:[]},
        {fieldName:'createdTimestamp', displayName: 'Created', editable: false, type: 'Timestamp', rules:[]},
        {fieldName:'useCount', displayName: 'Use Count', editable: false, type: Number, rules:[]},
        {fieldName:'passkeyActions', displayName: '', editable: false, type: Number, rules:[]},
        ],"emptyTableText":"No Passkeys (yet)","hideFilter":""}}),_c('br'),_c(VCardActions,[_c(VBtn,{on:{"click":_setup.startAssertion}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("$userPasskey")]),_vm._v(" Validate")],1),_c('br'),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_setup.showCreatePasskeyDialog = true}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("$userPasskey")]),_vm._v(" Create Passkey")],1),_c('br')],1),_c('a',{attrs:{"href":"https://www.youtube.com/watch?v=bdp8RdjV6PU","target":"_blank"}},[_vm._v("About Passkeys")]),_c(VDialog,{attrs:{"max-width":"1023"},model:{value:(_setup.showCreatePasskeyDialog),callback:function ($$v) {_setup.showCreatePasskeyDialog=$$v},expression:"showCreatePasskeyDialog"}},[_c(_setup.Card,{attrs:{"title":"Create Passkey","icon":"$userPasskey","inDialog":""}},[_c(_setup.DynamicForm,{key:_setup.showCreatePasskeyDialog,attrs:{"object":{name: _vm.$store.state.user.username},"fields":[{displayName: 'Name', fieldName:'name', type: String}],"save-method":_setup.startRegistration,"cancel-callback":() => _setup.showCreatePasskeyDialog = false,"success-callback":() => _setup.showCreatePasskeyDialog = false,"focus-index":0}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }