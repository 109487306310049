<template>
  <v-data-table
    v-if="transactions && headers"
    style="border-collapse: separate;"
    :items="transactions"
    :headers="headers"
    single-expand
    :expanded.sync="transactionRowExpanded"
    hide-default-footer
    disable-pagination
    disable-sort
    show-expand
    :item-class="getTransactionRowClass"
    no-data-text="No transactions found."
  >
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" style="padding-bottom: 8px; padding-top: 8px;">
        <div v-for="account in accounts" :key="account.id">
          <div v-if="account.id === item.accountId">
            <v-text-field
              label="Account"
              v-model="account.name"
              inset outlined dense disabled
            />
          </div>
        </div>
        <div>
          <v-select
            v-model="item.incomeSourceId"
            label="Income Source"
            placeholder="None (Expense)"
            persistent-placeholder
            inset outlined dense clearable
            :disabled="isSaving"
            :items="incomeSources"
            item-value="id"
            item-text="name"
          />
        </div>

        <div v-if="item.assetId">
          <div v-if="item.assetType === 'FINANCIAL_MODULE_SECURITY'">
            <div v-for="security in mySecurities" :key="security.id">
              <div v-if="security.id === item.assetId">
                Asset: {{security.name}}
              </div>
            </div>
          </div>

          Quantity: {{item.quantity}} <br/>
          Price: {{formatMoney(item.price)}} <br/>
          Fees: {{formatMoney(item.fees)}} <br/>

          <v-btn color="primary" small :elevation="0"
                 @click="saveUITransactionWithoutEnvelopes(item)"
                 :disabled="isSaving"
                 style="float: right;">
            Save
          </v-btn>
        </div>
        <div v-else-if="item.newEnvelopes">
          <draggable v-model="item.newEnvelopes" :disabled="isSaving">
            <div v-for="(envelope, index) in item.newEnvelopes" :key="index" style="display: flex; margin: 8px; cursor: move;">
              <v-icon>mdi-drag-horizontal</v-icon>
              <v-text-field
                dense full-width
                style="display:inline-flex; width: 100%"
                outlined
                invalid-label="Memo"
                v-model="envelope.memo"
                @keyup.native.enter="saveUITransaction(item)"
                :placeholder="item.memo || item.description"
                validate-on-blur
                :disabled="isSaving"
                hide-details
              ></v-text-field>
              <v-select
                style="min-width: 260px; height: 40px"
                v-model="envelope.envelopeId"
                inset outlined dense
                :disabled="isSaving"
                :items="envelopes"
                item-value="id"
                item-text="name"
              >
                <template v-slot:item="{ item }">
                  <div>
                    {{item.name}}
                    (<span :style="item.amount < 0 ? 'color: #FF0000' : ''">{{formatMoney(item.amount)}}</span>)
                  </div>
                </template>
                <template v-slot:selection="{ item }">
                  <div>
                    {{item.name}}
                    (<span :style="item.amount < 0 ? 'color: #FF0000' : ''">{{formatMoney(item.amount)}}</span>)
                  </div>
                </template>
              </v-select>
              <v-currency-field
                dense
                style="display:inline-block; float: right; min-width: 130px"
                outlined label="Amount" v-model="envelope.amount"
                @keyup.native.enter="saveUITransaction(item)"
                :rules="item.rules"
                validate-on-blur
                :disabled="isSaving"
                v-on:change="updateLastAmount(item.newEnvelopes, item.amount, index)"
                prefix="$"
                hide-details
              ></v-currency-field>
              <v-btn small icon :elevation="0"
                     @click="item.newEnvelopes.splice(index, 1);"
                     :disabled="isSaving"
                     style="float: right; margin: auto;">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </draggable>
          <v-btn color="primary" small :elevation="0"
                 @click="saveUITransaction(item)"
                 :disabled="isSaving || Math.round(item.amount * 100) !== Math.round(item.newEnvelopes.reduce((a, b) => a + (b.amount || 0), 0) * 100)"
                 style="float: right;">
            Save
          </v-btn>
          <v-btn
            v-if="item.envelopes && item.envelopes.length > 0"
            small :elevation="0"
            @click="item.newEnvelopes.push({memo: '', envelopeId: financialModule.id, amount: Math.round((item.amount - item.newEnvelopes.reduce((a, b) => a + (b.amount || 0), 0))*100) / 100});"
            :disabled="isSaving"
            style="float: right;">
            <v-icon>mdi-cash-plus</v-icon>
          </v-btn>
          <v-btn
            v-if="item.envelopes && item.envelopes.length > 0"
            small :elevation="0"
            @click="item.newEnvelopes.splice(0, item.newEnvelopes.length); getPaycheckEnvelopes(item.amount).forEach((e) => item.newEnvelopes.push(e));"
            :disabled="isSaving"
            style="float: right;">
            <v-icon>mdi-cash-fast</v-icon>
          </v-btn>
        </div>
        <ButtonWithConfirmation
          v-if="(!item.sourceId || item.pending) && item.id != item.accountId"
          small
          :confirmation-text='"Are you sure you want to delete this transaction?"'
          confirmation-icon="mdi-delete"
          button-text="Delete"
          button-color="error"
          :action="() => $api.financial_transaction.deleteUITransaction(item)"
          :success-callback="() => {}"
        />
      </td>
    </template>

    <template v-slot:item.date="{ item }">
      <!--          {{ // new Date(value).toDateString() }}-->
      <div v-if="item.sourceId">
        {{ item.date }}
      </div>
      <div v-else>
        <v-menu
          v-model="item.showDatePickerMenu"
          :close-on-content-click="true"
          :nudge-left="350"
          transition="scale-transition"
          offset-y
          min-width="460"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              style="height: 40px;"
              v-model="item.date"
              :rules="[rules.required]"
              readonly
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            landscape color="primary"
            v-model="item.date"
            @input="item.showDatePickerMenu = false"
            v-on:change="$api.financial_transaction.saveUITransactionWithoutEnvelopes(item)"
          ></v-date-picker>
        </v-menu>
      </div>
    </template>

    <template v-slot:item.assetId="{ item }">
      <div v-if="item.assetType === 'FINANCIAL_MODULE_SECURITY'">
        <div v-for="security in mySecurities" :key="security.id">
          <div v-if="security.id === item.assetId">
            {{security.name}}
          </div>
        </div>
      </div>
    </template>

    <template v-slot:item.incomeSourceId="{ item }">
      <v-select
        v-model="item.incomeSourceId"
        inset dense clearable
        :items="incomeSources"
        v-on:change="$api.financial_transaction.saveUITransactionWithoutEnvelopes(item)"
        item-value="id"
        item-text="name"
      />
    </template>

    <template v-slot:item.description="{ item }">
      <div v-if="item.assetId || !item.envelopes || item.envelopes.length === 0">
        <v-text-field
          style="height: 40px; display:inline-flex; width: 100%;"
          v-model="item.memo"
          :placeholder="item.description"
          v-on:change="$api.financial_transaction.saveUITransactionWithoutEnvelopes(item)"
          v-on:keyup.enter="$event.target.blur()"
          dense
          :hint="item.memo && item.memo !== item.description ? item.description : ''"
          persistent-hint
        ></v-text-field>
      </div>
      <div v-else>
        <div v-if="item.envelopes.length === 1">
          <v-text-field
            style="height: 40px; display:inline-flex; width: 100%;"
            v-model="item.envelopes[0].memo"
            :placeholder="item.memo || item.description"
            v-on:change="$api.financial_transaction.saveEnvelopeTransaction(item.envelopes[0])"
            v-on:keyup.enter="$event.target.blur()"
            dense
            :hint="item.envelopes[0].memo && item.envelopes[0].memo !== (item.memo || item.description) ? (item.memo || item.description) : ''"
            persistent-hint
          ></v-text-field>
        </div>
        <div v-else>{{ item.description }}</div>
      </div>
    </template>

    <!--        <template v-slot:item.memo="{ item }">-->
    <!--          <div v-if="!item.envelopes || item.envelopes.length === 0">-->
    <!--          </div>-->
    <!--          <div v-else-if="item.envelopes.length === 1">-->
    <!--            <v-text-field-->
    <!--              style="height: 40px; display:inline-flex; width: 100%;"-->
    <!--              v-model="item.envelopes[0].memo"-->
    <!--              :placeholder="item.memo"-->
    <!--              v-on:change="$api.financial_transaction.saveEnvelopeTransaction(item.envelopes[0])"-->
    <!--              v-on:keyup.enter="$event.target.blur()"-->
    <!--              dense-->
    <!--            ></v-text-field>-->
    <!--          </div>-->
    <!--          <div v-else>Multiple</div>-->
    <!--        </template>-->
    <template v-slot:item.envelopeId="{ item }">
      <div v-if="!item.envelopes || item.envelopes.length === 0">
      </div>
      <div v-else-if="item.envelopes.length === 1">
        <v-btn-toggle
          v-if="showQuickAssignEnvelopeButton && item.envelopes && item.envelopes[0] && item.financialModuleId === item.envelopes[0].envelopeId && item.envelopes[0].suggestedEnvelopeIds && item.envelopes[0].suggestedEnvelopeIds.length > 0"
        >
          <v-btn
            v-if="item.envelopes[0].suggestedEnvelopeIds[0] === 'payroll'"
            color="primary"
            @click="transactionRowExpanded.push(item); item.newEnvelopes.splice(0, item.newEnvelopes.length); getPaycheckEnvelopes(item.amount).forEach((e) => item.newEnvelopes.push(e));"
            small
          >
            <v-icon>mdi-cash-fast</v-icon>
          </v-btn>
          <v-btn
            v-else
            color="primary"
            @click="item.envelopes[0].envelopeId = item.envelopes[0].suggestedEnvelopeIds[0]; $api.financial_transaction.saveEnvelopeTransaction(item.envelopes[0]);"
            small
          >
            {{envelopeMap[item.envelopes[0].suggestedEnvelopeIds[0]].name}}
            <!--        <br/>-->
            <!--        {{formatMoney(envelopeMap[item.suggestedEnvelopeIds[0]].amount)}}-->
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon>
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(envelopeItem, index) in envelopesForUITransaction(item)"
                :key="index"
                dense
                @click="item.envelopes[0].envelopeId = envelopeItem.id; $api.financial_transaction.saveEnvelopeTransaction(item.envelopes[0]);"

              >
                <v-list-item-title>
                  {{envelopeItem.name}}
                  (<span :style="envelopeItem.amount < 0 ? 'color: #FF0000' : ''">{{formatMoney(envelopeItem.amount)}}</span>)
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-btn-toggle>
        <v-select
          v-else
          style="height: 40px;"
          v-model="item.envelopes[0].envelopeId"
          :items="envelopesForUITransaction(item)"
          item-value="id"
          item-text="name"
          dense
          v-on:change="item.envelopes[0].envelopeId && $api.financial_transaction.saveEnvelopeTransaction(item.envelopes[0]);"
        >
          <!--          item-text="name"-->
          <template v-slot:item="{ item }">
            <div>
              {{item.name}}
              (<span :style="item.amount < 0 ? 'color: #FF0000' : ''">{{formatMoney(item.amount)}}</span>)
            </div>
          </template>
          <!--          <template v-slot:selection="{ data }">-->
          <!--            <div>-->
          <!--              {{data.name}}-->
          <!--              (<span :style="data.amount < 0 ? 'color: #FF0000' : ''">{{formatMoney(data.amount)}}</span>)-->
          <!--            </div>-->
          <!--          </template>-->
        </v-select>
      </div>
      <div v-else>
        Multiple
        <span v-if="envelopeIdToAdd">
          <span v-for="envelope in item.envelopes" :key="envelope.id">
            <span v-if="envelope.envelopeId === envelopeIdToAdd">
              - {{envelopeMap[envelope.envelopeId].name}}:
              {{formatMoney(envelope.amount)}}, Total: {{formatMoney(item.amount)}}
            </span>
          </span>
        </span>
      </div>
    </template>

    <template v-slot:item.amount="{ item }">
      <div v-if="item.sourceId">
        {{formatMoney(item.amount)}}
        <!--            <span v-if="item.pending"> (Pending)</span>-->
      </div>
      <div v-else>
        <v-currency-field
          dense
          v-model="item.amount"
          prefix="$"
          v-on:blur="$api.financial_transaction.saveUITransactionWithoutEnvelopes(item)"
          @keyup.native.enter="$event.target.blur()"
        ></v-currency-field>
      </div>
    </template>
    <template v-slot:item.price="{ item }">
      <div>
        {{formatMoney(item.price)}}
      </div>
    </template>

    <template v-slot:item.pending="{ item }">
      <v-checkbox
        v-if="!item.assetId"
        :disabled="!!item.sourceId"
        v-on:change="$api.financial_transaction.saveUITransactionWithoutEnvelopes(item)"
        v-model="item.pending"
      />
    </template>

    <template v-slot:item.data-table-expand="{ isExpanded, expand }">
      <!--    <template v-slot:item.data-table-expand="{ }">-->
      <!--      <v-btn @click="expand(true)" v-if="!isExpanded">Expand</v-btn>-->
      <!--      <v-btn @click="expand(false)" v-if="isExpanded">close</v-btn>-->
      <button type="button" tabindex="-1" @click="expand(!isExpanded)" :class="'v-icon notranslate v-data-table__expand-icon v-icon--link mdi mdi-chevron-down theme--dark' + (isExpanded ? ' v-data-table__expand-icon--active' : '') "></button>
    </template>
  </v-data-table>
</template>

<script>
import draggable from 'vuedraggable'
import ButtonWithConfirmation from '@/components/ButtonWithConfirmation.vue'

export default {
  name: 'TransactionTable',
  components: { ButtonWithConfirmation, draggable },

  props: ['financialModule', 'accounts', 'envelopes', 'mySecurities', 'transactions', 'showQuickAssignEnvelopeButton', 'envelopeIdToAdd', 'incomeSources'],

  data: () => ({
    transactionSubscription: null,
    headers: null,
    transactionRowExpanded: [],
    isSaving: false,

    rules: {
      required: value => !!value || 'Required',
    },
  }),

  computed: {
    envelopeMap() {
      const names = {};
      this.envelopes.forEach(envelope => {
        names[envelope.id] = envelope
      });
      return names;
    },
  },

  methods: {
    subscribe() {
      if (this.transactionSubscription)
        this.$api.unsubscribe(this.transactionSubscription);

      this.transactionSubscription = this.$api.financial_transaction.subscribeToTransactions(this.financialModule.id, newTransaction => {
        if (newTransaction.envelopes)
          newTransaction.newEnvelopes = JSON.parse(JSON.stringify(newTransaction.envelopes));
        let foundIndex = null;
        let found = false;
        if (this.transactions) {
          this.transactions.forEach((transaction, index) => {
            if (newTransaction.id === transaction.id) {
              found = true;
              foundIndex = index;
            }
          });
        }

        let envelopeMatch = false;
        if (!found && this.envelopeIdToAdd && newTransaction.envelopes) {
          newTransaction.envelopes.forEach(envelope => {
            if (envelope.envelopeId === this.envelopeIdToAdd)
              envelopeMatch = true;
          });
        }

        if (found) {
          if (newTransaction.deletedTimestamp)
            // eslint-disable-next-line
            this.transactions.splice(foundIndex, 1);
          else
            // eslint-disable-next-line
            this.transactions.splice(foundIndex, 1, newTransaction);
        } else if (envelopeMatch) {
          // eslint-disable-next-line
          this.transactions.splice(0, 0, newTransaction);
        }
      });
    },
    reloadHeaders() {
      const headers = []
      headers.push({ text: 'Amount', value: 'amount', width: '144px' });

      let showInvestmentColumns = false;
      let showEnvelopeColumns = false;
      this.transactions.forEach(transaction => {
        if (transaction.assetId)
          showInvestmentColumns = true;
        if (transaction.envelopes && transaction.envelopes.length > 0)
          showEnvelopeColumns = true;
      });

      if (showInvestmentColumns && !showEnvelopeColumns) {
        headers.push({ text: 'Quantity', value: 'quantity' })
        headers.push({ text: 'Price', value: 'price' })
        headers.push({ text: 'Investment', value: 'assetId' })
        headers.push({ text: 'Income Source', value: 'incomeSourceId' })
      }

      // headers.push({ text: 'Memo / Description', value: 'description', width: '300px' });
      headers.push({ text: 'Memo / Description', value: 'description' });
      // if (showEnvelopeColumns && !showInvestmentColumns) {
      if (showEnvelopeColumns) {
        // this.headers.push({ text: 'Memo', value: 'memo' });
        // this.headers.push({ text: 'Category', value: 'categoryId' });
        // if (this.showQuickAssignEnvelopeButton)
        //   headers.push({ text: '', value: 'quickAssignEnvelopeButton', align: 'end', width: '5px' });
        // headers.push({ text: 'Envelope', value: 'envelopeId', width: '240px' });
        headers.push({ text: 'Envelope', value: 'envelopeId', width: '300px' });
        // this.headers.push({ text: 'Envelope', value: 'envelopeId' });
      }
      if (showEnvelopeColumns)
        headers.push({ text: 'Pending', value: 'pending', width: '5px' });
      headers.push({ text: 'Date', value: 'date', width: '144px' });
      headers.push({ text: '', value: 'data-table-expand' });

      this.headers = headers;
    },
    saveUITransaction(uiTransaction) {
      this.isSaving = true;
      this.$api.financial_transaction.saveUITransaction(uiTransaction).finally(() => {
        this.isSaving = false;
      })
    },
    saveUITransactionWithoutEnvelopes(uiTransaction) {
      this.isSaving = true;
      this.$api.financial_transaction.saveUITransactionWithoutEnvelopes(uiTransaction).finally(() => {
        this.isSaving = false;
      })
    },
    envelopesForUITransaction(uiTransaction) {
      let anyUncategorized = false;
      const suggestedEnvelopeIds = [];
      uiTransaction.envelopes.forEach(envelope => {
        if (envelope.suggestedEnvelopeIds)
          envelope.suggestedEnvelopeIds.forEach(suggestedEnvelopeId => {
            suggestedEnvelopeIds.push(suggestedEnvelopeId);
          });
        suggestedEnvelopeIds.push(envelope.envelopeId);
        if (uiTransaction.financialModuleId === envelope.envelopeId)
          anyUncategorized = true;
      });

      if (!anyUncategorized || !suggestedEnvelopeIds || suggestedEnvelopeIds.length < 1) {
        return this.envelopes;
      }
      const envelopes = [];

      // if (uiTransaction.envelopes[0].memo) {
      //   console.dir(uiTransaction.envelopes[0].memo);
      //   console.dir(suggestedEnvelopeIds);
      // }

      this.envelopes.forEach(envelope => {
        if (suggestedEnvelopeIds.includes(envelope.id))
          envelopes.push(envelope);
      });

      this.envelopes.forEach(envelope => {
        if (!suggestedEnvelopeIds.includes(envelope.id))
          envelopes.push(envelope);
      });

      return envelopes;
    },
    getTransactionRowClass(item) {
      return "";
    },
    getPaycheckEnvelopes(totalAmount) {
      const memo = 'Paycheck';
      const transactionEnvelopes = [];

      let remainingEnvelopeId = this.financialModule.id;
      this.envelopes.forEach(envelope => {
        if (envelope.targetInterval === 'PAYCHECK' && envelope.targetAmount)
          transactionEnvelopes.push({ memo: memo, envelopeId: envelope.id, amount: envelope.targetAmount });
        else if (envelope.targetInterval === 'MONTHLY' && envelope.targetAmount)
          transactionEnvelopes.push({ memo: memo, envelopeId: envelope.id, amount: Math.round(envelope.targetAmount * 100 / 2) / 100 });
        else if (envelope.targetInterval === 'ANNUAL' && envelope.targetAmount)
          transactionEnvelopes.push({ memo: memo, envelopeId: envelope.id, amount: Math.round(envelope.targetAmount * 100 / 24) / 100 });
        else if (envelope.targetInterval === 'TOP_UP' && envelope.targetAmount) {
          let amount = envelope.targetAmount - envelope.amount;
          if (amount > envelope.maxAmountPerPaycheck)
            amount = envelope.maxAmountPerPaycheck;
          if (amount < 0)
            amount = 0;
          transactionEnvelopes.push({ memo: memo, envelopeId: envelope.id, amount: amount });
        } else if (envelope.targetInterval === 'REMAINING_BALANCE' && envelope.targetAmount)
          remainingEnvelopeId = envelope.id;
      });

      transactionEnvelopes.push({ memo: memo, envelopeId: remainingEnvelopeId, amount: Math.round((totalAmount - transactionEnvelopes.reduce((a, b) => a + (b.amount || 0), 0)) * 100) / 100 });

      return transactionEnvelopes;
    },
    updateLastAmount(newEnvelopes, total, index) {
      if (!newEnvelopes || newEnvelopes.length < 2 || index === (newEnvelopes.length - 1))
        return;

      newEnvelopes[newEnvelopes.length - 1].amount += total - newEnvelopes.reduce((a, b) => a + (b.amount || 0), 0)
    }
  },

  watch: {
    'transactions.length': function(val, oldVal) {
      this.reloadHeaders();
    },
  },

  mounted() {
    this.subscribe();
    this.reloadHeaders();
  },

  destroyed() {
    if (this.transactionSubscription)
      this.$api.unsubscribe(this.transactionSubscription);
  }
}
</script>

<style>
td.text-start {
  overflow: hidden;
}
</style>
